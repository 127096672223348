





















































































































































































































































































































import Vue from "vue";
import { PullRefresh, CellGroup, Cell, Dialog, Toast, Field, Button, ActionSheet, Popup } from "vant";
import Box2ConfigEdit from "@/components/box2-config-edit.vue";
export default Vue.extend({
  components: {
    Box2ConfigEdit,
    [PullRefresh.name]: PullRefresh,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [Button.name]: Button,
    [ActionSheet.name]: ActionSheet,
    [Popup.name]: Popup
  },
  data() {
    return {
      isRefreshing: false,
      showRoomGrid: true,
      roomText: "通道",
      showRoom: false,
      data: {
        cabinet: {},
        rooms: [],
        cabinetType: 0,
        managerName: "",
        no: 0,
        showBattery: false,
        showCharge: false,
        showChargeOrder: false,
        showDebug: false,
        showRepairTask: false,
        showReplaceOrder: false,
        showSiteEditor: false,
        showTaskManager: false,
        showUpgrade: false,
        totalData: "-",
        usedData: "-"
      },
      cabinet: {
        androidVersion: "",
        box2Id: 0,
        business: { name: "" },
        site: {},
        cabinetElectric: 0,
        cabinetType: 0,
        connectVersion: 0,
        electricUnit: 0,
        endTime: "",
        error: 0,
        fan: 0,
        flow: "-",
        gsm: 0,
        hardwareVersion: 0,
        iccId: "",
        iccidBusinessName: 0,
        id: 0,
        index: "-",
        isOnline: true,
        isUpdate: 0,
        lat: 0,
        lng: 0,
        offset: 0,
        pulse: 0,
        qrCode: "",
        softwareVersion: 0,
        startTime: "",
        status: 0,
        task: "",
        taskDoorId: 0,
        temp: "0℃",
        updateTime: ""
      },
      room: {
        batteryHardwareVersion: 0,
        batteryId: 0,
        batterySoc: 0,
        batteryTimeOut: true,
        box2Id: 0,
        cabinetId: 0,
        cabinetRoomId: 0,
        chargeExactTime: "",
        chargeLoadMsg: "",
        chargeState: 0,
        chargeStateMsg: "",
        chargeStatus: 0,
        chargeTime: "",
        cutOffI: "",
        errorCode: "",
        getChargeParamMsg: "",
        getHeatStateMsg: "",
        hardwareVersion: 0,
        i: "",
        index: "",
        isConnect: false,
        isOnline: false,
        isRent: false,
        isUpdate: 0,
        isUsed: false,
        lockState: 0,
        lockStateMsg: "",
        mode: "",
        msg: "",
        no: 0,
        p: "",
        power: "",
        roomId: 0,
        safePower: "",
        safeTime: "",
        setI: "",
        setU: "",
        soc: "",
        softwareVersion: 0,
        startModeMsg: "",
        temp: 0,
        type: 0,
        u: "",
        updateTime: "",
        wh: ""
      },
      box2Config: {
        box2Id: 0,
        cardDiscountTime: 0,
        cardFee: 0,
        cardPassword: "",
        cardSector: 0,
        coinDiscountNumber: 0,
        coinDiscountTime: 0,
        commandType: 0,
        firstPower: 0,
        firstTimeEffect: 0,
        fourthPower: 0,
        fourthTimeEffect: 0,
        isActive: 0,
        isBurnIn: 0,
        isCardAllow: 0,
        isChargeTimePowerEffected: 0,
        isCheckPayload: 0,
        isCoinAllow: 0,
        isCoinDiscounted: 0,
        isMemorizeOnPowerOff: 0,
        isOnTestMode: 0,
        isQueryOnKeyTen: 0,
        isShowCardMoney: 0,
        isStopOnFullyCharged: 0,
        isVoicePlayPlaned: 0,
        maxChargeTime: 0,
        maxCoin: 0,
        maxPower: 0,
        maxTemp: 0,
        monthlyCardChargeTime: 0,
        monthlyCardLockTime: 0,
        powerOnFullyCharged: 0,
        rentFee: 0,
        secondPower: 0,
        secondTimeEffect: 0,
        thirdPower: 0,
        thirdTimeEffect: 0,
        unitChargeTime: 0,
        userId: 0,
        voicePlayEndTime: 0,
        voicePlayStartTime: 0,
        voiceType: 255,
        voiceTypeMsg: "",
        waitTimeOnFullyCharged: 0,
        waitTimeOnPayloadDrop: 0
      },
      searchForm: {
        qrCode: this.$route.query.qrCode,
        roomId: this.$route.query.roomId,
        box2Id: this.$route.query.box2Id,
        cabinetId: this.$route.query.cabinetId,
        no: this.$route.query.no
      },
      cabinetInitRoomDialog: {
        show: false,
        count: 0
      },
      roomAdjustDialog: {
        show: false,
        v: 0,
        i: 0
      },
      roomChargeParamDialog: {
        show: false,
        data: {
          energy: 2,
          chargingVoltage: 0,
          chargingCurrent: 0,
          safeTime: 0,
          cutoffCurrent: 0,
          safePower: 0,
          heatTemp: 0,
          heatTime: 0,
          startStop: 0
        }
      },
      showCabinetDebugActionSheet: false,
      showRoomDebugActionSheet: false,
      showErrorCodePopup: false,
      showBox2ConfigEdit: false
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onRefresh() {
      this.isRefreshing = true;
      const callback = () => {
        setTimeout(() => {
          this.getData().then(() => {
            this.isRefreshing = false;
          });
        }, 3000);
      };
      if (this.showRoom) {
        this.roomQuery().then(callback);
      } else {
        this.box2Query().then(callback);
      }
    },
    getData() {
      this.showRoomGrid = false;
      return this.$axios.post("/api/manage/cabinet/getCabinetDetail", this.searchForm).then(res => {
        const e = res.data;
        this.data = e.data;
        this.cabinet = e.data.cabinet;
        this.room = e.data.rooms[0];
        this.cabinetInitRoomDialog.count = e.data.rooms.length;
        if (e.data.box2Config) {
          this.box2Config = e.data.box2Config;
        }
      });
    },
    /**
     * 网关
     */
    box2Query() {
      return this.$axios.post("/api/manage/box2Query", { box2Id: this.cabinet.box2Id });
    },
    box2Reboot() {
      Dialog.confirm({ title: "确认是否重启" }).then(() => {
        this.$axios.post("/api/manage/box2Reboot", { box2Id: this.cabinet.box2Id }).then(() => {
          Toast.success("重启指令下发成功");
        });
      });
    },
    box2Upgrade() {
      Dialog.confirm({ title: "是否升级网关" }).then(() => {
        this.$axios.post("/api/manage/box2Update", { cabinetId: this.cabinet.id }).then(() => {
          Toast.success("升级指令下发成功");
        });
      });
    },
    cabinetInitRoomShowDialog() {
      this.cabinetInitRoomDialog.show = true;
    },
    cabinetInitRoom() {
      const count = this.cabinetInitRoomDialog.count;
      if (count == this.data.rooms.length) {
        Toast.fail("请填写与当前路数不同的数字");
        return;
      }
      Dialog.confirm({ title: "是否确定要修改为" + count + "路" }).then(() => {
        this.$axios.post("/api/manage/cabinet/cabinetRoomInit", { cabinetId: this.cabinet.id, count: count }).then(() => {
          Toast.success("重置指令下发成功");
          this.getData();
        });
      });
    },
    /**
     * 通道
     */
    roomQuery() {
      return this.$axios.post("/api/manage/roomQuery", { cabinetRoomId: this.room.cabinetRoomId });
    },
    roomUpgrade() {
      Dialog.confirm({ title: "是否升级通道" }).then(() => {
        this.$axios.post("/api/manage/roomUpdate", { cabinetRoomId: this.room.cabinetRoomId }).then(() => {
          Toast.success("升级指令下发成功");
        });
      });
    },
    roomDoorOpen() {
      Dialog.confirm({ title: "是否打开仓门" }).then(() => {
        this.$axios.post("/api/manage/roomOpen", { cabinetRoomId: this.room.cabinetRoomId }).then(() => {
          Toast.success("打开仓门指令下发成功");
        });
      });
    },
    roomDoorClose() {
      Dialog.confirm({ title: "是否关闭仓门" }).then(() => {
        this.$axios.post("/api/manage/roomClose", { cabinetRoomId: this.room.cabinetRoomId }).then(() => {
          Toast.success("关闭仓门指令下发成功");
        });
      });
    },
    roomAdjust() {
      const data = {
        cabinetRoomId: this.room.cabinetRoomId,
        v: this.roomAdjustDialog.v,
        i: this.roomAdjustDialog.i
      };
      Dialog.confirm({ title: "是否按照电压" + data.v + ", 电流" + data.i + "校准" }).then(() => {
        this.$axios.post("/api/manage/roomAdjust", data).then(() => {
          Toast.success("校准参数指令下发成功");
        });
      });
    },
    roomStartCharge() {
      const data = {
        cabinetRoomId: this.room.cabinetRoomId,
        energy: 2,
        startStop: 2
      };
      Dialog.confirm({ title: "是否开始充电" }).then(() => {
        this.$axios.post("/api/manage/roomStart", Object.assign(data, this.getRoomChargeParam())).then(() => {
          Toast.success("开始充电指令下发成功");
        });
      });
    },
    roomStopCharge() {
      const data = {
        cabinetRoomId: this.room.cabinetRoomId,
        energy: 2
      };
      Dialog.confirm({ title: "是否停止充电" }).then(() => {
        this.$axios.post("/api/manage/roomStart", Object.assign(data, this.getRoomChargeParam())).then(() => {
          Toast.success("停止充电指令下发成功");
        });
      });
    },
    roomSuspendCharge() {
      const data = {
        cabinetRoomId: this.room.cabinetRoomId,
        energy: 1
      };
      Dialog.confirm({ title: "是否停用充电" }).then(() => {
        this.$axios.post("/api/manage/roomStart", Object.assign(data, this.getRoomChargeParam())).then(() => {
          Toast.success("停用充电指令下发成功");
        });
      });
    },
    roomStartChargeOnParam() {
      const data = this.roomChargeParamDialog.data;
      if (data.chargingVoltage != 0 && (data.chargingVoltage < 3000 || data.chargingVoltage > 9000)) {
        Toast.fail("充电电压数值无效");
        return;
      }
      if (data.heatTemp < -10 || data.heatTemp > 20) {
        Toast.fail("加热温度数值无效");
        return;
      }
      if (data.heatTime < 0 || data.heatTime > 20) {
        Toast.fail("加热时间数值无效");
        return;
      }
      Dialog.confirm({ title: "是否下发配置充电参数" }).then(() => {
        this.$axios.post("/api/manage/roomStart", Object.assign({ cabinetRoomId: this.room.cabinetRoomId }, data)).then(() => {
          Toast.success("配置充电参数下发成功");
        });
      });
    },
    getRoomChargeParam() {
      return {
        energy: 0,
        chargingVoltage: 0,
        chargingCurrent: 0,
        safeTime: 0,
        cutoffCurrent: 0,
        safePower: 0,
        heatTemp: 0,
        heatTime: 0,
        startStop: 0
      };
    },
    /**
     * 跳转
     */
    toChargeOrder() {
      this.$toUrl("/web/manager/battery/charge_order/list?cabinetRoomId=" + this.room.cabinetRoomId + "&tab=-1");
    },
    toReplaceOrder() {
      this.$toUrl("/web/manager/battery_replace_order_list?phone=" + this.cabinet.qrCode + "&tab=-1");
    },
    toBattery() {
      localStorage.setItem("inputType", "3");
      localStorage.setItem("inputValue", this.room.batteryId + "");
      localStorage.setItem("searchType", "0");
      this.$toUrl("/web/manager/device_detail");
    },
    toLocation() {
      this.$toUrl("/web/manager/device_location?lng=" + this.cabinet.lng + "&lat=" + this.cabinet.lat);
    },
    toBleLink() {
      this.$toMiniAppWeb("/pages/ble-link/ble-link?qrCode=" + this.cabinet.qrCode + "&box2Id=" + this.cabinet.box2Id);
    },
    toMeterReading() {
      this.$toUrl("/web/manager/manager_device_detail_electric_pulse?cabinetId=" + this.cabinet.id);
    },
    toSiteSet() {
      localStorage.setItem("cabinetId", this.cabinet.id + "");
      this.$toUrl("/web/manager/manager_switch_cabinet_information_editor?qrCode=" + this.cabinet.qrCode);
    },
    toDataRecord() {
      localStorage.setItem("cabinetRoomId", this.room.cabinetRoomId + "");
      localStorage.setItem("cabinetType", this.cabinet.cabinetType + "");
      this.$toUrl("/web/manager/manager_switch_cabinet_datarecord");
    },
    toRepairAdd() {
      localStorage.setItem("managerName", this.data.managerName);
      const url = "/web/manager/repair_add?deviceType=3";
      this.$toUrl(url + `&box2Id=${this.room.box2Id}&roomId=${this.room.roomId || ""}&qrCode=${this.cabinet.qrCode}&doorId=${this.room.no}`);
    },
    toTaskAdd() {
      localStorage.setItem("managerName", this.data.managerName);
      this.$toUrl(`/web/manager/task_add?deviceType=3&box2Id=${this.room.box2Id}&roomId=${this.room.roomId || ""}&qrCode=${this.cabinet.qrCode}&doorId=${this.room.no}`);
    }
  }
});
