
















































































































































import Vue from "vue";
import { Toast, Form, Field, Switch, Button, CellGroup } from "vant";

export default Vue.extend({
  props: ["config", "cabinetId"],
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Switch.name]: Switch,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button
  },
  data() {
    return {
      show: false,
      form: {
        cardDiscountTime: 0,
        cardFee: 0,
        cardPassword: "",
        cardSector: 0,
        coinDiscountNumber: 0,
        coinDiscountTime: 0,
        commandType: 0,
        firstPower: 0,
        firstTimeEffect: 0,
        fourthPower: 0,
        fourthTimeEffect: 0,
        isActive: 0,
        isBurnIn: 0,
        isCardAllow: 0,
        isChargeTimePowerEffected: 0,
        isCheckPayload: 0,
        isCoinAllow: 0,
        isCoinDiscounted: 0,
        isMemorizeOnPowerOff: 0,
        isOnTestMode: 0,
        isQueryOnKeyTen: 0,
        isShowCardMoney: 0,
        isStopOnFullyCharged: 0,
        isVoicePlayPlaned: 0,
        maxChargeTime: 0,
        maxCoin: 0,
        maxPower: 0,
        maxTemp: 0,
        monthlyCardChargeTime: 0,
        monthlyCardLockTime: 0,
        powerOnFullyCharged: 0,
        rentFee: 0,
        secondPower: 0,
        secondTimeEffect: 0,
        thirdPower: 0,
        thirdTimeEffect: 0,
        unitChargeTime: 0,
        userId: 0,
        voicePlayEndTime: 0,
        voicePlayStartTime: 0,
        voiceType: 0,
        voiceTypeMsg: "",
        waitTimeOnFullyCharged: 0,
        waitTimeOnPayloadDrop: 0
      },
      ui: {
        switchSize: 24,
        isChargeTimeLimited: false,
        isVoiceOn: false,
        isCheckTemp: false
      }
    };
  },
  watch: {
    "ui.isVoiceOn": "onVoiceOnOff",
    "ui.isCheckTemp": "onCheckTempOnOff",
    "ui.isChargeTimeLimited": "onChargeTimeLimitOnOff"
  },
  created() {
    const config = this.formattedConfig();
    this.form = Object.assign({}, this.form, config);
    this.ui.isVoiceOn = config.voiceType != 255;
    this.ui.isChargeTimeLimited = config.maxChargeTime != 0;
    this.ui.isCheckTemp = config.maxTemp != 0;
  },
  methods: {
    onVoiceOnOff(isVoiceOn: boolean) {
      this.form.voiceType = isVoiceOn ? this.form.voiceType : 255; // 0xff
    },
    onCheckTempOnOff(isCheckTemp: boolean) {
      this.form.maxTemp = isCheckTemp ? this.form.maxTemp : 0;
    },
    onChargeTimeLimitOnOff(isChargeTimeLimited: boolean) {
      this.form.maxChargeTime = isChargeTimeLimited ? this.form.maxChargeTime : 0;
    },
    save() {
      if (!this.validate()) {
        return;
      }
      this.$axios.post("/api/manage/cabinet/updateBox2Config", Object.assign({ cabinetId: this.cabinetId }, this.form)).then(() => {
        Toast.success("指令下发成功");
      });
    },
    validate() {
      const numberRules = [
        { key: "maxTemp", start: 0, end: 100, msg: "温度报警值" },
        { key: "waitTimeOnPayloadDrop", start: 0, end: 300, msg: "负载脱落等待时间" },
        { key: "maxCoin", start: 1, end: 9, msg: "最大投币数" },
        { key: "unitChargeTime ", start: 1, end: 999, msg: "一元充电时间" },
        { key: "maxPower", start: 0, end: 1500, msg: "最大功率" },
        { key: "cardSector", start: 0, end: 15, msg: "刷卡扇区" },
        { key: "monthlyCardLockTime", start: 1, end: 999, msg: "包月卡锁定时间" },
        { key: "monthlyCardChargeTime", start: 1, end: 65535, msg: "包月卡供电时间" },
        { key: "cardFee", start: 0, end: 255, msg: "电子钱包刷卡费率" },
        { key: "cardDiscountTime", start: 0, end: 600, msg: "刷卡优惠时间" },
        { key: "rentFee", start: 0, end: 1000, msg: "线下占仓费率" },
        { key: "voicePlayStartTime", start: 0, end: 24, msg: "语音开始时间" },
        { key: "voicePlayEndTime", start: 0, end: 24, msg: "语音结束时间" },
        { key: "coinDiscountNumber", start: 2, end: 5, msg: "投币优惠起始投币" },
        { key: "coinDiscountTime", start: 5, end: 180, msg: "投币优惠的时间" },
        { key: "firstPower", start: 10, end: 800, msg: "第一档功率" },
        { key: "secondPower", start: 10, end: 800, msg: "第二档功率" },
        { key: "thirdPower", start: 10, end: 800, msg: "第三档功率" },
        { key: "fourthPower", start: 10, end: 800, msg: "第四档功率" },
        { key: "firstTimeEffect", start: 0, end: 200, msg: "第一档功率减时间" },
        { key: "secondTimeEffect", start: 0, end: 200, msg: "第二档功率减时间" },
        { key: "thirdTimeEffect", start: 0, end: 200, msg: "第三档功率减时间" },
        { key: "fourthTimeEffect", start: 0, end: 200, msg: "第四档功率减时间" },
        { key: "powerOnFullyCharged", start: 10, end: 100, msg: "充满判断功率" },
        { key: "waitTimeOnFullyCharged", start: 1, end: 240, msg: "充满等待时间" },
        { key: "maxChargeTime", start: 0, end: 24, msg: "充电限制时间" }
      ];
      for (const [k, v] of Object.entries(this.form)) {
        for (const rule of numberRules) {
          if (k == rule.key) {
            if (v < rule.start || v > rule.end) {
              Toast.fail(rule.msg + "设置错误(" + rule.start + " - " + rule.end + ")");
              return false;
            }
          }
        }
      }
      if (this.ui.isVoiceOn && this.form.voiceType == 255) {
        Toast.fail("请选择语言类型");
        return false;
      }
      if (!/^[0-9]{8}$/.test(this.form.cardPassword)) {
        Toast.fail("刷卡密码必须是8位数字");
        return false;
      }
      if (this.form.firstPower >= this.form.secondPower || this.form.secondPower >= this.form.thirdPower || this.form.thirdPower >= this.form.fourthPower) {
        Toast.fail("第一/二/三/四档功率必须递增");
        return false;
      }
      return true;
    },
    formattedConfig() {
      const config = this.config;
      config.isActive = Boolean(config.isActive);
      config.isBurnIn = Boolean(config.isBurnIn);
      config.isCardAllow = Boolean(config.isCardAllow);
      config.isChargeTimePowerEffected = Boolean(config.isChargeTimePowerEffected);
      config.isCheckPayload = Boolean(config.isCheckPayload);
      config.isCoinAllow = Boolean(config.isCoinAllow);
      config.isCoinDiscounted = Boolean(config.isCoinDiscounted);
      config.isMemorizeOnPowerOff = Boolean(config.isMemorizeOnPowerOff);
      config.isOnTestMode = Boolean(config.isOnTestMode);
      config.isQueryOnKeyTen = Boolean(config.isQueryOnKeyTen);
      config.isShowCardMoney = Boolean(config.isShowCardMoney);
      config.isStopOnFullyCharged = Boolean(config.isStopOnFullyCharged);
      config.isVoicePlayPlaned = Boolean(config.isVoicePlayPlaned);
      return config;
    }
  }
});
